



















import { Component, Mixins, Watch } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import { EventPayload } from '@movecloser/front-core'

import { ProductData } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'

import { CartGiftMixin } from '../../../shared/mixins/gifts.mixin'
import { DeliveryDateMixin } from '../../../shared/mixins/delivery-date.mixin'
import { DynamicHelperMixin } from '../../../shared/mixins/dynamicHelper.mixin'
import { FullscreenLoader } from '../../../shared/molecules/Loader'
import { logger } from '../../../../support'
import { ToastMixin } from '../../../shared'
import { RecommendationAction } from '../../../products/contracts/repositories'
import { SidesLayout } from '../../../shared/molecules/SidesLayout'
import { SuggestedProductsMixin } from '../../../shared/mixins/suggestedProducts.mixin'
import {
  SuggestedProductsSource
} from '../../../shared/contracts/suggestedProducts'

import { BaseCartMixin } from '../../shared/mixins/base-cart.mixin'

import { CartSummary } from '../../organisms/CartSummary'
import { SingleCartItem } from '../../organisms/SingleCartItem'

import {
  CART_VIEW_CONFIG_MAP,
  CART_VIEW_KEY,
  cartViewComponent,
  CartViewConfig
} from './Cart.config'
import { CartViewGroupMixin } from './Cart.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<CartView>({
  name: 'CartView',
  components: { CartSummary, SidesLayout, SingleCartItem, FullscreenLoader },
  async created () {
    this.setFullScreenLoading(true)
    this.config = this.getComponentConfig(CART_VIEW_KEY, { ...CART_VIEW_CONFIG_MAP })

    try {
      await this.initLoadGift()
      await this.initLoadSamples()
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.setFullScreenLoading(false)
    }
  },
  async mounted () {
    if (this.shouldLoadSuggestedProducts) {
      /**
       * @inheritDoc
       */
      await this.handleSuggestedProductsLoad(
        null,
        SuggestedProductsSource.CartView,
        RecommendationAction.Recommendation,
        this.loadSuggestedProductsByDelivery
      )
    }

    if (this.shouldLoadGifts) {
      this.handleGiftsRestoreLoader()
    }
    this.initDynamicEventsData()
  }
})
export class CartView extends Mixins<CartViewGroupMixin>(ToastMixin, BaseCartMixin, DeliveryDateMixin, CartGiftMixin, StructureConfigurable, SuggestedProductsMixin, DynamicHelperMixin) {
  protected config!: CartViewConfig
  public loadFullScreen: boolean = false
  public cartSuggestedProductsRef = null
  public suggestedProductsLoaded = false

  @Watch('cart')
  protected async onCartChange () {
    await this.tryLoadingSuggestedProductsByFreeDelivery()
    await this.initLoadSamples()
  }

  @Watch('cartSuggestedProductsRef')
  public onSuggestedProductsRefsUpdate (ref: HTMLDivElement | null) {
    this.handleContentShow(ref)
  }

  public get hasUnavailableCartItems (): boolean {
    if (!this.cart) {
      return false
    }
    return this.cart.items.some((item) => item.outOfStock)
  }

  /**
   * Render component for Cart View
   */
  public get cartComponentLayout (): VueConstructor {
    const layout = this.getConfigProperty<string>('layout')
    return cartViewComponent[layout]
  }

  /**
   * Determines whether loading suggested products should include amount below free delivery value
   */
  public get loadSuggestedProductsByDelivery (): boolean {
    return this.getConfigProperty<boolean>('loadSuggestedProductsByDelivery')
  }

  /**
   * Determines whether cart should load suggested products list
   */
  public get shouldLoadSuggestedProducts (): boolean {
    return this.getConfigProperty<boolean>('shouldLoadSuggestedProducts')
  }

  /**
   * Determines whether cart should load gifts
   */
  public get shouldLoadGifts (): boolean {
    return this.getConfigProperty<boolean>('shouldLoadGifts')
  }

  /**
   * Determines whether cart should load samples
   */
  public get shouldLoadSamples (): boolean {
    return this.getConfigProperty<boolean>('shouldLoadSamples')
  }

  public get shouldSendDynamicContentEvents (): boolean {
    return this.getConfigProperty('shouldSendDynamicContentEvents')
  }

  /**
   * Composes a content for dynamic data events
   * @see DynamicContent
   */
  public initDynamicEventsData (): void {
    if (!this.shouldSendDynamicContentEvents) {
      return
    }

    this.composeDynamicContentData(
      this.$t('front.checkout.views.CartView.enhanced.suggestedProducts.heading').toString(),
      SuggestedProductsSource.CartView
    )
  }

  /**
   * Sets full screen loader
   */
  public setFullScreenLoading (value: boolean) {
    this.loadFullScreen = value
  }

  protected async initLoadGift (uid: string | null = null) {
    if (this.shouldLoadGifts && this.cart && this.cart.items.length > 0) {
      /**
       * @inheritDoc
       */
      await this.loadGifts(this.cart.items, uid)
    }
  }

  protected async initLoadSamples () {
    if (this.shouldLoadSamples && this.cart && this.cart.items.length > 0) {
      /**
       * @inheritDoc
       */
      await this.loadSamples(this.cart.items)
    }
  }

  protected onItemResign () {
    console.info('Resigned from item')
  }

  public async onItemSelect (payload: Record<string, ProductData>): Promise<void> {
    if (!payload.product.sku) {
      logger('Could not add sample product to card. There is no valid payload to hande the action!', 'warn')
      return
    }

    this.loadFullScreen = true
    this.canPickSamples = false

    const tempVariants = []
    for (const variant of Object.values(payload.product.variants)) {
      tempVariants.push(variant)
    }

    try {
      await this.addFirstItemToCart(tempVariants.splice(0, 1)[0])
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.loadFullScreen = false
    }
  }

  /**
   * Removes fullScreenLoader after a gift from restored cart item has been correctly added to the cart.
   * Restored cart item = its quantity has been raised from '0' to '1'
   * @protected
   */
  protected handleGiftsRestoreLoader (): void {
    this.eventBus.handle('cart:gifts-loader-emit', (event: EventPayload<boolean>) => {
      this.setFullScreenLoading(!!event.payload)
    })
  }

  /**
   * Tries to load suggested products only once, when the cart exists
   * @protected
   */
  protected async tryLoadingSuggestedProductsByFreeDelivery (): Promise<void> {
    if (this.cart && this.loadSuggestedProductsByDelivery && !this.suggestedProductsLoaded) {
      await this.handleSuggestedProductsLoad(
        null,
        SuggestedProductsSource.CartView,
        RecommendationAction.Recommendation,
        this.loadSuggestedProductsByDelivery
      )

      this.suggestedProductsLoaded = true
    }
  }
}

export default CartView
